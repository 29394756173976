@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;

.link {
  align-items: end;
  aspect-ratio: 16 / 9;
  border-radius: $yds-border-radius-large;
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: minmax(0, 1fr);
  position: relative;
  text-decoration: none;

  @include breakpoints.media(tablet) {
    aspect-ratio: initial;
    height: 5.5rem;
  }

  @include breakpoints.media(desktop-small) {
    height: 6rem;
  }

  @include breakpoints.media(desktop) {
    height: 6.875rem;
  }
}

.image {
  border-radius: $yds-border-radius-large;
  object-fit: cover;
  object-position: top;
  transition: filter 0.3s;

  .link:hover & {
    filter: brightness(108%);
  }
}

.title {
  @include typography.default-s-bold;
  margin: 0;
  max-height: 100%;
  overflow-wrap: break-word;
  overflow: hidden;
  padding: spacing.$spacing-16;
  position: relative;
  text-wrap: balance;

  @include breakpoints.media(desktop) {
    @include typography.default-m-bold;
  }
}
