@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';

.root {
  font: inherit;
  @include typography.default-s-bold;

  align-items: center;
  background-color: $yds-color-white-opacity-5;
  border-radius: 4px;
  border: none;
  color: $yds-color-text-default;
  cursor: pointer;
  display: inline-flex;
  margin: 0;
  min-height: 2rem;
  text-align: left;
  text-decoration: none;

  padding-top: spacing.$spacing-6;
  padding-bottom: spacing.$spacing-6;
  padding-left: spacing.$spacing-12;
  padding-right: spacing.$spacing-12;

  &.large {
    padding-top: spacing.$spacing-12;
    padding-bottom: spacing.$spacing-12;
    padding-left: spacing.$spacing-16;
    padding-right: spacing.$spacing-16;
  }

  &.center {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  &:active {
    opacity: 0.8;
  }
  &:hover {
    background-color: $yds-color-white-opacity-20;
  }
  &:focus {
    box-shadow:
      0 0 0 2px $yds-color-background,
      0 0 0 4px $yds-color-border-secondary-focus;
    outline: none;
    &:not(:focus-visible) {
      box-shadow: none;
    }
  }
  &:focus-visible {
    box-shadow:
      0 0 0 2px $yds-color-background,
      0 0 0 4px $yds-color-border-secondary-focus;
    outline: none;
  }

  &Disabled {
    cursor: not-allowed;
    opacity: 0.3;
  }
  &Selected {
    background-color: rgba($yds-color-feedback-highlight, 0.24);
    &:hover {
      background-color: rgba($yds-color-feedback-highlight, 0.24);
    }
  }
  &WithIcon {
    padding-left: spacing.$spacing-4;
  }
}

.icon {
  flex: none;
  height: 18px;
  width: 18px;
  margin-right: spacing.$spacing-4;

  svg {
    height: auto;
    width: 100%;
  }
}
