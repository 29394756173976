.placeholder {
  display: block;
}
.lg {
  height: 3.5rem;
}
.md {
  height: 3rem;
}
.sm {
  height: 2.5rem;
}
.xs {
  height: 2rem;
}
