@use 'styles/typography';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;

.searchBar {
  height: 3rem;
  flex: 1;
  background-color: $yds-color-white;
  border-radius: 0.5rem;
  border: none;

  color: $yds-color-text-negative;
  padding: 0 3.11rem;
  @include typography.default-m-bold;

  &::placeholder {
    color: $yds-color-text-negative;
    opacity: 1; /* Firefox */
  }
  &:focus::placeholder {
    color: $yds-color-text-input-placeholder;
  }
}

.inputContainer {
  display: flex;
  position: relative;
}

.inputIcon {
  pointer-events: none;
  height: 1.5rem;
  width: 1.5rem;
  color: $yds-color-text-negative;

  position: absolute;
  top: 0.75rem;
  left: 0.88rem;
}

.clearIcon {
  width: 2rem;
  height: 2rem;
  padding: 0.25rem;
  color: $yds-color-text-negative;
  border-radius: 6rem;

  .clearButton:hover & {
    background: $yds-color-black-opacity-20;
  }
}

.clearButton {
  position: absolute;
  height: 3rem;
  padding: 0 0.5rem;
  display: flex;
  align-items: center;
  right: 0;
  background: transparent;
  border: none;
}
