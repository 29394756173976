@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as dark;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-light' as light;
@use 'styles/spacing';
@use 'styles/typography';

.link {
  align-items: center;
  color: dark.$yds-color-text-default;
  display: inline-flex;
  flex-direction: row;
  gap: spacing.$spacing-4;
  text-decoration: underline;

  &:hover {
    background-color: dark.$yds-color-action-secondary-variant;
  }
}

.button {
  background-color: transparent;
  border: none;
  font: inherit;
  padding: 0;
}

.linkLight {
  color: light.$yds-color-text-default;

  &:hover {
    background-color: light.$yds-color-action-secondary-variant;
  }
  &:focus-visible {
    outline: 2px solid light.$yds-color-border-primary-focus;
  }
}

.size-xs.weight-regular {
  @include typography.default-xs;
}
.size-s.weight-regular {
  @include typography.default-s;
}
.size-m.weight-regular {
  @include typography.default-m;
}
.size-l.weight-regular {
  @include typography.default-l;
}
.size-xl.weight-regular {
  @include typography.default-xl;
}

.size-xs.weight-bold {
  @include typography.default-xs-bold;
}
.size-s.weight-bold {
  @include typography.default-s-bold;
}
.size-m.weight-bold {
  @include typography.default-m-bold;
}
.size-l.weight-bold {
  @include typography.default-l-bold;
}
.size-xl.weight-bold {
  @include typography.default-xl-bold;
}

.icon {
  flex: none;

  .size-xs & {
    width: 1.125rem;
    height: 1.125rem;
  }
  .size-s & {
    width: 1.3125rem;
    height: 1.3125rem;
  }
  .size-m & {
    width: 1.5rem;
    height: 1.5rem;
  }
  .size-l & {
    width: 1.6875rem;
    height: 1.6875rem;
  }
  .size-xl & {
    width: 1.875rem;
    height: 1.875rem;
  }
}
