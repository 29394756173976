@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';
@use 'styles/zIndex';
@use '../Navigation.module.scss' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.root {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: $yds-color-black;
  padding-bottom: spacing.$spacing-24;
  box-shadow: 0 24px 24px 0 rgba(0, 0, 0, 0.5);
  @include zIndex.areena-menu-level;
}

.closeButton {
  display: flex;
  height: 2.75rem;
  min-width: 2.75rem;
  background: none;
  border: none;
  align-items: center;
  justify-content: center;
  gap: spacing.$spacing-8;
  color: $yds-color-white-opacity-60;
  cursor: pointer;
  padding: 0;
  @include typography.default-s-bold;

  &:hover {
    color: $yds-color-gray-5;
  }

  &Icon {
    width: 1.5rem;
    height: 1.5rem;

    @include breakpoints.media(tablet-landscape) {
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  &Text {
    display: none;

    @include breakpoints.media(tablet-landscape) {
      display: block;
    }
  }
}

.upperPart {
  display: grid;
  align-items: center;
  grid-template-areas: '. logo close';
  grid-template-columns: 2.75rem 1fr 2.75rem;
  padding-top: spacing.$spacing-2;
  padding-bottom: spacing.$spacing-2;
  padding-left: spacing.$spacing-8;
  padding-right: spacing.$spacing-8;

  @include breakpoints.media(tablet-landscape) {
    --search-bar-max-width: 100%;

    grid-template-areas: 'logo . search . close';
    grid-template-columns:
      134px minmax(spacing.$spacing-40, 1fr) minmax(
        0,
        var(--search-bar-max-width)
      )
      minmax(spacing.$spacing-40, 1fr) 134px;
    padding-top: spacing.$spacing-12;
    padding-bottom: spacing.$spacing-24;
    padding-left: 5%;
    padding-right: 5%;
  }
  @include breakpoints.media(desktop-small) {
    --search-bar-max-width: 686px;
  }
  @include breakpoints.media(desktop) {
    --search-bar-max-width: 832px;
  }
}

.logoContainer {
  grid-area: logo;
  justify-self: center;

  @include breakpoints.media(tablet-landscape) {
    justify-self: start;
  }
}

.closeButtonContainer {
  grid-area: close;
  justify-self: end;
}

.upperPart .searchBarContainer {
  grid-area: search;
}

.lowerPart {
  display: flex;
  flex-direction: column;
  margin-left: 5%;
  margin-right: 5%;
  row-gap: spacing.$spacing-16;

  @include breakpoints.media(tablet) {
    align-self: center;
    width: 576px;
  }
  @include breakpoints.media(desktop-small) {
    width: 682px;
    row-gap: spacing.$spacing-24;
  }
  @include breakpoints.media(desktop) {
    width: 832px;
  }
}

.lowerPart .searchBarContainer {
  margin-top: spacing.$spacing-8;
  margin-bottom: spacing.$spacing-8;
}

.serviceSwitchContainer {
  display: flex;
}

.imageLinkContainer {
  --columns: 2;

  display: grid;
  gap: spacing.$spacing-16;
  grid-template-columns: repeat(var(--columns), minmax(0, 1fr));

  @include breakpoints.media(mobile-landscape) {
    --columns: 3;
  }

  &Podcast {
    @include breakpoints.media(tablet) {
      --columns: 4;
    }
  }
}

.categoryLinkContainer {
  display: flex;
  gap: spacing.$spacing-8;
  margin-top: spacing.$spacing-8;
  flex-wrap: wrap;
}

.textLinkContainer {
  display: flex;
  gap: spacing.$spacing-24;
  margin-top: spacing.$spacing-8;
}
