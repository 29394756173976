@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.root {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: $yds-color-gray-90;
  border-radius: 8px;
  padding: 40px 0;
}
