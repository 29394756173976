@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/zIndex';
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.root {
  position: relative;
  @include zIndex.areena-menu-level;
}

.mobile {
  background: $yds-color-black;
  display: block;

  @include breakpoints.media(tablet-landscape) {
    display: none;
  }
}

.desktop {
  display: none;
  height: 4.5rem;
  align-items: center;
  justify-content: space-between;
  padding: 0 5%;
  background: $yds-color-black;

  @include breakpoints.media(tablet-landscape) {
    display: flex;
  }
}

.desktopMiddleContainer {
  display: flex;
  align-items: center;
  margin-left: spacing.$spacing-40;
  margin-right: spacing.$spacing-24;
  height: 2.75rem;
  flex-grow: 1;
  gap: spacing.$spacing-24;
}

.list,
.list li {
  display: contents;
}

.desktopRightContainer {
  display: flex;
  align-items: center;
  height: 2.75rem;
  gap: spacing.$spacing-24;
  justify-content: flex-end;
}

.headerTopBar {
  display: flex;
  padding: spacing.$spacing-2 spacing.$spacing-8;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}

.headerBottomBar {
  display: flex;
  justify-content: center;
  gap: spacing.$spacing-24;
  align-items: center;
  height: 3rem;
  list-style: none;
  margin: 0;
  padding: 0;

  li {
    display: contents;
  }
}

%button {
  display: flex;
  height: 2.75rem;
  min-width: 2.75rem;
  text-decoration: none;
  background: transparent;
  border: none;
  align-items: center;
  justify-content: center;
  gap: spacing.$spacing-8;
  color: $yds-color-white-opacity-60;
  cursor: pointer;
  padding: 0;
  @include typography.default-s-bold;

  &:hover {
    color: $yds-color-gray-5;
  }

  &Active {
    color: $yds-color-turquoise-40;
  }
}

.searchButton {
  @extend %button;

  @include breakpoints.media(tablet-landscape) {
    justify-content: flex-start;
    flex-grow: 1;
  }
}

.loginButton {
  @extend %button;
}

.icon {
  height: 1.5rem;
  width: 1.5rem;
  color: inherit;

  @include breakpoints.media(tablet-landscape) {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.contentOverlay {
  background-color: rgba($yds-color-black-opacity-95, 0.6);
  position: fixed;
  inset: 0;
  overflow-y: auto;
  @include zIndex.areena-menu-level;
}
