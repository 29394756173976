@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/shadow-dark' as *;
@use 'styles/zIndex';
@use 'styles/spacing';
@use 'styles/typography';

.trigger {
  display: flex;
  appearance: none;
  background: transparent;
  border: none;
  color: $yds-color-text-default;
  cursor: pointer;
  padding: 0;
  width: 2.75rem;
  height: 2.75rem;
  align-items: center;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  background-color: $yds-color-background-variant;
  border-radius: $yds-border-radius-large;
  color: $yds-color-text-default;
  width: 18.75rem;
  max-width: var(--radix-dropdown-menu-content-available-width);
  box-shadow: $yds-shadow-sm;
  animation: fadeIn 0.2s ease-out;

  @include zIndex.areena-menu-level;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-1.25rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.group {
  border-top: 1px solid $yds-color-border-separator;
  padding-top: spacing.$spacing-8;
  padding-bottom: spacing.$spacing-8;
}

.item {
  display: grid;
  grid-template-columns: auto 1fr auto;
  text-decoration: none;
  gap: spacing.$spacing-16;
  padding: spacing.$spacing-8 spacing.$spacing-16;
  align-items: center;
  background: transparent;
  border: none;
  font: inherit;
  color: inherit;
  cursor: pointer;

  @include typography.default-s;

  &[data-highlighted] {
    background-color: $yds-color-white-opacity-20;
  }

  &:focus {
    outline-style: none;
  }
}

button.item {
  margin: 0; // Reset Safari button margin
}

.icon {
  grid-column: 1;
  width: 1.25rem;
  height: 1.25rem;

  &:last-child {
    grid-column: 3;
  }
}
