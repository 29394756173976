@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/audio-player-dimensions';
@use 'styles/breakpoints';

@font-face {
  font-display: swap;
  font-family: 'Yle Next';
  src:
    url('https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar.woff2')
      format('woff2-variations'),
    url('https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar.woff')
      format('woff-variations');
  font-weight: 300 900;
  font-style: normal;
}

@font-face {
  font-display: swap;
  font-family: 'Yle Next';
  src:
    url('https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar-Italic.woff2')
      format('woff2-variations'),
    url('https://design-system.cdn.yle.fi/fonts/yle-next/1.0.0/YleNextVar-Italic.woff')
      format('woff-variations');
  font-weight: 300 900;
  font-style: italic;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

*:focus {
  outline: 2px solid $yds-color-border-secondary-focus;
  outline-offset: 2px;
}
*:focus:not(:focus-visible) {
  outline: none;
}
*:focus-visible {
  outline: 2px solid $yds-color-border-secondary-focus;
  outline-offset: 2px;
}

/* html and body font-sizes are left to user agent on purpose */

html {
  color-scheme: dark;

  &.isAudioPlayerVisible {
    scroll-padding-bottom: audio-player-dimensions.$height-mobile;

    @include breakpoints.media(tablet-landscape) {
      scroll-padding-bottom: audio-player-dimensions.$height-tablet-landscape;
    }
  }
}

body {
  font-family: 'Yle Next', sans-serif;
  background-color: $yds-color-background;
  color: $yds-color-text-default;
  margin: 0;

  &.preventScroll {
    overflow: hidden;
  }
}

a {
  color: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  /* Just to make sure no one uses heading levels for adjusting the font-size
     https://css-tricks.com/accessible-font-sizing-explained/#what-about-headings */
  font-size: 1rem;
}
