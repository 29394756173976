@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';

.searchCardHorizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0; // create local stacking contect, does not change the stack level in current stacking context
}

.withAspectRatio16_9 {
  margin-right: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 9rem;

  @include breakpoints.media(mobile-landscape) {
    width: 9rem;
  }

  @include breakpoints.media(tablet-landscape) {
    width: 7.25rem;
  }

  @include breakpoints.media(desktop-xl) {
    width: 9rem;
  }
}

.withAspectRatio1_1 {
  margin-right: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 5.5rem;

  @include breakpoints.media(mobile-landscape) {
    width: 5.5rem;
  }

  @include breakpoints.media(tablet-landscape) {
    width: 4.5rem;
  }

  @include breakpoints.media(desktop-xl) {
    width: 5.5rem;
  }
}

.searchCardLink {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;

  overflow: hidden;
  text-decoration: none;
  @include typography.default-s-bold;

  @include breakpoints.media(desktop-xl) {
    @include typography.default-m-bold;
    -webkit-line-clamp: 3;
  }

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.highlightWrapper {
  position: absolute;
  top: spacing.$spacing-6;
  left: spacing.$spacing-6;
}

.title {
  @include typography.default-m-bold;
  margin-top: 0;
  margin-bottom: 0;

  .searchCardHorizontal & {
    @include breakpoints.media(desktop) {
      @include typography.default-l-bold;
    }
  }
}

.labels {
  margin-top: spacing.$spacing-4;
}
