@use 'styles/typography';
@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/breakpoints';

.link {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  height: 2.75rem;
  min-width: 2.75rem;
  align-items: center;
  text-decoration: none;
  justify-content: center;
  color: $yds-color-white-opacity-60;

  @media (hover: hover) {
    &:hover {
      color: $yds-color-gray-5;
    }
  }
}

.activeLink {
  color: $yds-color-turquoise-40;
}

.icon {
  height: 1.25rem;
  width: 1.25rem;
  color: inherit;
}

.linkText {
  color: inherit;
  @include typography.default-s-bold;
}
