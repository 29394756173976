/* autoprefixer grid: no-autoplace */
@use 'styles/breakpoints';

$sm-cols: 12;
$lg-cols: 24;

.gridContainer {
  display: grid;
  padding: 0;
  margin: 0;
  grid-template: auto / repeat($sm-cols, 1fr);
  gap: 16px;
  min-width: 0;

  @include breakpoints.media(desktop-small) {
    grid-template: auto / repeat($lg-cols, 1fr);
    gap: 24px;
  }
}

.smallGap {
  gap: 4px;
}

.gridItem {
  min-width: 0;
  list-style: none;
}

.mobile0 {
  display: none;
}

@for $i from 1 through $sm-cols {
  .mobile#{$i} {
    display: unset;
    grid-column: span $i;
  }
}

@include breakpoints.media(mobile-landscape) {
  .mobileLandscape0 {
    display: none;
  }

  @for $i from 1 through $sm-cols {
    .mobileLandscape#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

@include breakpoints.media(tablet) {
  .tablet0 {
    display: none;
  }

  @for $i from 1 through $sm-cols {
    .tablet#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

@include breakpoints.media(tablet-landscape) {
  .tabletLandscape0 {
    display: none;
  }

  @for $i from 1 through $sm-cols {
    .tabletLandscape#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

@include breakpoints.media(desktop-small) {
  .desktopSmall0 {
    display: none;
  }

  @for $i from 1 through $lg-cols {
    .desktopSmall#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

@include breakpoints.media(desktop) {
  .desktop0 {
    display: none;
  }

  @for $i from 1 through $lg-cols {
    .desktop#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

@include breakpoints.media(desktop-xl) {
  .desktopXL0 {
    display: none;
  }

  @for $i from 1 through $lg-cols {
    .desktopXL#{$i} {
      display: unset;
      grid-column: span $i;
    }
  }
}

/* Order classes */

@for $i from 0 through $sm-cols {
  .orderMobile#{$i} {
    order: $i;
  }
}

@include breakpoints.media(mobile-landscape) {
  @for $i from 0 through $sm-cols {
    .orderMobileLandscape#{$i} {
      order: $i;
    }
  }
}

@include breakpoints.media(tablet) {
  @for $i from 0 through $sm-cols {
    .orderTablet#{$i} {
      order: $i;
    }
  }
}

@include breakpoints.media(tablet-landscape) {
  @for $i from 0 through $sm-cols {
    .orderTabletLandscape#{$i} {
      order: $i;
    }
  }
}

@include breakpoints.media(desktop-small) {
  @for $i from 0 through $lg-cols {
    .orderDesktopSmall#{$i} {
      order: $i;
    }
  }
}

@include breakpoints.media(desktop) {
  @for $i from 0 through $lg-cols {
    .orderDesktop#{$i} {
      order: $i;
    }
  }
}

@include breakpoints.media(desktop-xl) {
  @for $i from 0 through $lg-cols {
    .orderDesktopXL#{$i} {
      order: $i;
    }
  }
}
