@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';

.initials {
  @include typography.default-xs-bold;
  font-size: 0.625rem; // Override to 10px

  align-items: center;
  background-color: $yds-color-action-primary;
  border-radius: $yds-border-radius-full;
  color: $yds-color-text-negative;
  display: flex;
  height: 1.75rem;
  justify-content: center;
  margin: 0; // Reset figure default margin
  text-align: center;
  transition: opacity 0.2s ease-in-out;
  width: 1.75rem;

  &[hidden] {
    opacity: 0;
  }

  &SizeMd {
    @include typography.default-m-bold;
    width: 2.75rem;
    height: 2.75rem;
  }
}
