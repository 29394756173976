@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/typography';
@use 'styles/spacing';

.searchCardHorizontal {
  align-items: center;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 0;

  animation-name: pulse;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-fill-mode: both;

  @for $i from 2 through 10 {
    :nth-child(#{$i}) > & {
      animation-delay: ($i - 1) * 0.3s;
    }
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 0.999;
  }
  50% {
    opacity: 0.8;
  }
}

.withAspectRatio16_9 {
  margin-right: 1rem;
  width: 9rem;

  @include breakpoints.media(mobile-landscape) {
    width: 9rem;
  }

  @include breakpoints.media(tablet-landscape) {
    width: 7.25rem;
  }

  @include breakpoints.media(desktop-xl) {
    width: 9rem;
  }

  .image {
    padding-top: 56.25%;
    background-color: $yds-color-background-interactive;
  }
}

.withAspectRatio1_1 {
  margin-right: 1rem;
  flex-grow: 0;
  flex-shrink: 0;
  width: 5.5rem;

  @include breakpoints.media(mobile-landscape) {
    width: 5.5rem;
  }

  @include breakpoints.media(tablet-landscape) {
    width: 4.5rem;
  }

  @include breakpoints.media(desktop-xl) {
    width: 5.5rem;
  }

  .image {
    padding-top: 100%;
    background-color: $yds-color-background-interactive;
  }
}

.title {
  margin-top: 0;
  margin-bottom: 0;
  background-color: $yds-color-background-interactive;
  max-width: 100%;
  width: 8.5rem;
  height: 1.5rem;
  animation: 1s pulse infinite;
  animation-delay: 0.4s;
}
