.root {
  display: flex;
  height: 2.75rem;
  align-items: center;
}

.logo {
  width: 8.375rem;
  height: 1.25rem;
}
