@use 'styles/spacing';

.group {
  display: flex;
  flex-wrap: wrap;
  column-gap: spacing.$spacing-24;
  row-gap: spacing.$spacing-16;
}
.groupVertical {
  flex-direction: column;
}
