@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as dark;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-light' as light;
@use 'styles/spacing';
@use 'styles/breakpoints';
@use 'styles/typography';

.button {
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border: none;
  font: inherit;
  cursor: pointer;
  margin: 0;
  max-width: 100%;
  padding: 0;
  text-decoration: none;

  &.themeLight {
    &:focus-visible {
      outline-color: light.$yds-color-border-primary-focus;
    }
  }
}

/* Modifiers */

.disabled {
  cursor: not-allowed;
}

/* Common size related properties */

.width-full {
  width: 100%;
}

.text-align-left {
  text-align: left;
}

.text-align-center {
  text-align: center;
}

.size-lg {
  @include typography.default-m-bold;

  .icon {
    width: 1.75rem;
    height: 1.75rem;
  }
}

.size-md {
  @include typography.default-m-bold;

  .icon {
    width: 1.5rem;
    height: 1.5rem;
  }
}

.size-sm {
  @include typography.default-s-bold;

  .icon {
    width: 1.25rem;
    height: 1.25rem;
  }

  @include breakpoints.media(mobile-landscape) {
    width: unset;
  }
}

.size-xs {
  @include typography.default-xs-bold;

  .icon {
    width: 1rem;
    height: 1rem;
  }
}

/* Variants */

.variant-primary,
.variant-secondary,
.variant-text.padding-default {
  .text {
    margin-left: spacing.$spacing-8;
    margin-right: spacing.$spacing-8;
  }

  &.size-lg {
    min-height: 3.5rem;
    padding: spacing.$spacing-12 spacing.$spacing-24;

    &.iconOnly {
      padding: 0;
      width: 3.5rem;
    }
  }
  &.size-md {
    min-height: 3rem;
    padding: spacing.$spacing-8 spacing.$spacing-16;

    &.iconOnly {
      padding: 0;
      width: 3rem;
    }
  }
  &.size-sm {
    min-height: 2.5rem;
    padding: spacing.$spacing-8 spacing.$spacing-16;

    &.iconOnly {
      padding: 0;
      width: 2.5rem;
    }
  }
  &.size-xs {
    min-height: 2rem;
    padding: spacing.$spacing-4 spacing.$spacing-8;

    &.iconOnly {
      padding: 0;
      width: 2rem;
    }
  }
}

.variant-primary {
  background-color: dark.$yds-color-action-primary;
  border-radius: 100px;
  color: dark.$yds-color-text-negative;

  &.themeLight {
    background-color: light.$yds-color-action-primary;
    color: light.$yds-color-text-negative;
  }

  &:hover,
  &:active {
    background-color: dark.$yds-color-action-primary-variant;

    &.themeLight {
      background-color: light.$yds-color-action-primary-variant;
    }
  }

  &.disabled {
    background-color: dark.$yds-color-action-disabled;
    color: dark.$yds-color-text-disabled;

    &.themeLight {
      background-color: light.$yds-color-action-disabled;
      color: light.$yds-color-text-disabled;
    }

    &:hover {
      background-color: dark.$yds-color-action-disabled;

      &.themeLight {
        background-color: light.$yds-color-action-disabled;
      }
    }
  }
}

.variant-secondary {
  background-color: transparent;
  border: 2px solid dark.$yds-color-action-primary;
  border-radius: 100px;
  color: dark.$yds-color-text-default;

  &.themeLight {
    border-color: light.$yds-color-action-primary;
    color: light.$yds-color-text-default;
  }

  &:hover {
    background-color: dark.$yds-color-action-secondary-variant;

    &.themeLight {
      background-color: light.$yds-color-action-secondary-variant;
    }
  }

  &:active {
    background-color: dark.$yds-color-action-secondary-variant;
    border-color: dark.$yds-color-action-primary-variant;

    &.themeLight {
      background-color: light.$yds-color-action-secondary-variant;
      border-color: light.$yds-color-action-primary-variant;
    }
  }

  &.disabled {
    border-color: dark.$yds-color-border-disabled;
    color: dark.$yds-color-text-disabled;

    &.themeLight {
      border-color: light.$yds-color-border-disabled;
      color: light.$yds-color-text-disabled;
    }

    &:hover {
      background-color: transparent;
    }
  }
}

.variant-text {
  background-color: transparent;
  color: inherit;

  &.themeLight {
    color: light.$yds-color-text-default;
  }

  &.disabled {
    color: dark.$yds-color-text-disabled;

    &.themeLight {
      color: light.$yds-color-text-disabled;
    }
  }

  &.padding-default {
    border-radius: 100px;

    &:hover,
    &:active {
      background-color: dark.$yds-color-action-secondary-variant;

      &.themeLight {
        background-color: light.$yds-color-action-secondary-variant;
      }
    }
  }
  &.padding-vertical {
    border-radius: 8px;
    gap: spacing.$spacing-8;
    padding-top: spacing.$spacing-12;
    padding-bottom: spacing.$spacing-12;

    &.size-lg {
      min-height: 3.5rem;
    }
    &.size-md {
      min-height: 3rem;
    }
    &.size-sm {
      min-height: 2.5rem;
    }
    &.size-xs {
      min-height: 2rem;
    }
  }
  &.padding-horizontal {
    border-radius: 8px;
    gap: spacing.$spacing-8;
    padding-left: spacing.$spacing-24;
    padding-right: spacing.$spacing-24;
  }
  &.padding-none {
    border-radius: 8px;
    gap: spacing.$spacing-8;
  }
}
