@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/zIndex.scss';
@use 'styles/spacing.scss';

.skipLink {
  @include typography.default-s-bold;
  @include zIndex.skip-link-level;

  position: absolute;
  top: -100px;
  left: -100px;
  background-color: $yds-color-action-primary;
  color: $yds-color-text-negative;
  border-radius: 100px;
  text-decoration: none;
  white-space: nowrap;
  padding: spacing.$spacing-8 spacing.$spacing-24;

  &:focus {
    top: spacing.$spacing-8;
    left: spacing.$spacing-16;
    box-shadow:
      0 0 0 2px $yds-color-background,
      0 0 0 4px $yds-color-border-secondary-focus;
    outline: none;
  }
}
