@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing';
@use 'styles/typography';

.supportAlert {
  padding: spacing.$spacing-16;
  background-color: $yds-color-feedback-warning;
  color: $yds-color-text-negative;
  text-align: center;
  @include typography.default-s;
  position: relative;
  z-index: 1;
}
