@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/spacing';
@use 'styles/breakpoints';

.toast {
  background-color: $yds-color-white;
  border-radius: 8px;
  padding: spacing.$spacing-12 spacing.$spacing-16;
  display: inline-flex;
  align-items: center;
  gap: 0.75rem;
}

.icon {
  width: 1.5rem;
  height: 1.5rem;
  color: $yds-color-text-negative;
}

.text {
  @include typography.default-s-bold;
  color: $yds-color-text-negative;
}
