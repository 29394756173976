@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/spacing.scss';
@use 'styles/typography';
@use 'styles/breakpoints';

.footer {
  position: relative;
  margin-top: 2.5rem;
}

.areenaLinksSection {
  padding: spacing.$spacing-24 0;
  display: grid;
  grid-template-columns: repeat(auto-fill, 12.25rem);
  border-top: 1px solid $yds-color-border-separator;
  gap: spacing.$spacing-24;

  @include breakpoints.media(mobile-landscape) {
    gap: spacing.$spacing-48;
    padding: spacing.$spacing-32 0;
  }
}

.areenaLink {
  @include typography.default-s;

  &:hover .areenaLinkText {
    background-color: $yds-color-action-secondary-variant;
  }
}

.subLinksSection {
  display: flex;
  flex-direction: column;
  width: 12.25rem;
}

.linksContainer {
  display: flex;
  flex-direction: column;
  row-gap: spacing.$spacing-16;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: contents;
  }
}

.generalLinksSection {
  display: flex;
  flex-direction: column;
  border-top: 1px solid $yds-color-border-separator;

  @include breakpoints.media(tablet-landscape) {
    flex-direction: row;
    gap: 48px;
  }
}

.areenaLogo {
  width: 8.375rem;
  height: 1.25rem;
  display: block;
  margin-bottom: spacing.$spacing-16;
}

.externalIcon {
  margin-left: spacing.$spacing-4;
  font-size: 1.5rem;
}

.subTitle {
  margin-top: spacing.$spacing-24;
  margin-bottom: spacing.$spacing-16;
  padding: 0;
}

.yleFiLinkContainer {
  display: block;
  margin-top: spacing.$spacing-24;
  padding: spacing.$spacing-24 0;
  background-color: $yds-color-background-variant;
}
.yleFiLink {
  display: block;
  width: fit-content;
  @include typography.default-s-bold;
}

.yleFiLinkContent {
  display: flex;
  @include typography.default-s;
}
.yleLinkText {
  line-height: 2rem;
}
.yleLogo {
  width: 2rem;
  height: 2rem;
  margin-right: spacing.$spacing-24;
}
.yleRelatedLinkContainer {
  background-color: $yds-color-background-negative;
  color: $yds-color-text-negative;
  padding: spacing.$spacing-24 0;
}

.yleRelatedLinks {
  display: flex;
  flex-wrap: wrap;
  column-gap: spacing.$spacing-24;
  row-gap: spacing.$spacing-16;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: contents;
  }
}
