@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/typography';
@use 'styles/spacing';
@use 'styles/breakpoints';

@mixin withBackground {
  @include typography.default-s-bold;
  border-radius: 8px;
  color: $yds-color-text-negative;
  padding: spacing.$spacing-8 spacing.$spacing-16;
}

.notification {
  display: inline-flex;
  align-items: center;

  &.transparent {
    @include typography.default-s;
    min-height: 2.5rem;
  }

  &.primary {
    @include withBackground;
    background-color: $yds-color-action-primary;
  }

  &.error {
    @include withBackground;
    background-color: $yds-color-feedback-error;
  }

  &.text {
    @include typography.default-m;
    max-width: 42rem;
    flex-direction: column;
    align-items: start;
  }

  &.toast {
    width: 100%;
    min-height: 71px;

    @include breakpoints.media(mobile-landscape) {
      width: 21rem;
      max-width: 100%;
    }
  }
}

.controlWrapper {
  margin-top: spacing.$spacing-32;
}

.icon {
  flex-shrink: 0;
  width: 1.5rem;
  height: 1.5rem;
  margin-right: spacing.$spacing-8;

  .transparent & {
    fill: $yds-color-gray-40;
  }

  .primary &,
  .error & {
    fill: $yds-color-text-negative;
  }
}

.text {
  display: flex;
  flex-direction: column;

  .helpText {
    @include typography.default-s;
  }
}

.removeButton {
  border: none;
  background-color: transparent;
  color: inherit;
  cursor: pointer;
  margin-right: 0;
  margin-left: auto;
}
