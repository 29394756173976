@use 'styles/typography';
@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;

.link {
  display: flex;
  padding: 0.625rem 1rem;
  align-items: center;
  background: $yds-color-white-opacity-20;
  border-radius: 6.25rem;
  color: $yds-color-gray-5;
  text-decoration: none;
  @include typography.default-s-bold;

  &:hover {
    filter: brightness(108%);
  }
}
