@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

.labelList {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  list-style: none;
  padding: 0;
  margin: 0;
}

.genericLabel {
  @include typography.default-xs;
  color: $yds-color-gray-40;

  @include breakpoints.media(desktop) {
    @include typography.default-s;
  }

  &::after {
    margin: 0 0.5rem;
    content: '·';
  }

  &:last-child {
    &::after {
      content: none;
    }
  }
}

.highlightLabel {
  @include typography.default-xs-bold-ac;
  background-color: $yds-color-action-primary;
  border-radius: 4px;
  color: $yds-color-text-negative;
  display: flex;
  align-items: center;
  max-width: 100%;
  flex-basis: auto;
  padding: spacing.$spacing-2 spacing.$spacing-8;
  margin-right: spacing.$spacing-6;

  &:last-child {
    margin-right: 0;
  }
}
