@use '@yleisradio/yds-core/lib/tokens/build/scss/colors' as *;
@use 'styles/spacing';
@use 'styles/typography';

.link {
  display: flex;
  color: $yds-color-white-opacity-60;
  gap: spacing.$spacing-8;
  background: transparent;
  border: none;
  text-decoration: none;
  align-items: center;
  cursor: pointer;
  text-align: left;
  padding-top: spacing.$spacing-12;
  padding-bottom: spacing.$spacing-12;
}

.icon {
  color: inherit;
  width: 1.25rem;
  height: 1.25rem;
}

.text {
  color: inherit;
  @include typography.default-s-bold;
}
