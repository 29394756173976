@use '@yleisradio/yds-core/lib/tokens/build/scss/border-radius' as *;
@use '@yleisradio/yds-core/lib/tokens/build/scss/theme-dark' as *;
@use 'styles/breakpoints';
@use 'styles/spacing';
@use 'styles/typography';

$easing: cubic-bezier(0.4, 0, 0, 1);

.root {
  background-color: $yds-color-action-secondary-variant;
  border-radius: $yds-border-radius-full;
  display: inline-flex;
  gap: spacing.$spacing-2;
  position: relative;
  width: 100%;
  z-index: 0;

  @include breakpoints.media(mobile-landscape) {
    width: auto;
  }
}

.item {
  border: none;
  border-radius: $yds-border-radius-full;
  cursor: pointer;
  flex: 50%;
  font: inherit;
  background-color: transparent;
  color: $yds-color-text-default;
  margin: 0;
  outline-offset: 0;
  padding: spacing.$spacing-10 spacing.$spacing-24;
  transition:
    background-color 0.3s $easing,
    color 0.3s $easing;

  @include typography.default-s-bold;

  @include breakpoints.media(mobile-landscape) {
    flex: auto;
  }

  &[data-state='off']:hover {
    background-color: $yds-color-action-secondary;
  }

  &[data-state='on'] {
    color: $yds-color-text-negative;
  }
}

.activeMarker {
  background-color: $yds-color-feedback-highlight;
  border-radius: $yds-border-radius-full;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: -1;

  transition: all 0.3s $easing;
}
