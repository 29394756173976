@use 'styles/spacing';
@use 'styles/typography';

.root {
  align-items: center;
  display: grid;
  grid-template-areas:
    'initials nick'
    'initials username';
  grid-template-columns: auto 1fr;
  padding: spacing.$spacing-16;
}

.initials {
  grid-area: initials;
  margin-right: spacing.$spacing-16;
}

.nick {
  grid-area: nick;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include typography.default-m-bold;
}

.username {
  grid-area: username;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @include typography.default-s;
}
